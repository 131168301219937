
import Vue from 'vue';
import NimsEmsStep01Template from '@/templates/nims/NimsEmsStep01Template.vue';
import { getRefundsInfo, createRefundsForInfra } from '@/api/infra-renew/index';
import { ERROR_CODE_OBJ } from '@/assets/data/nims/constants';

export default Vue.extend({
  name: 'NimsEmsStep01Brain',
  components: { NimsEmsStep01Template },
  props: {},
  data() {
    return {
      isRender: false,
      formState: {
        terms: {
          agree1: false,
        },
        period: '1',
        startDate: '',
        groupExpireDate: '',
      },
      groupSeqNo: '',
      limitDate: '',
      paymentSeqNo: '',
      refundSeqNo: 0,
    };
  },
  watch: {},
  async mounted() {
    try {
      this.paymentSeqNo = this.$route.query?.payment_seqno ? String(this.$route.query?.payment_seqno) : '';

      await this.getRefundsInfoFnc();
      this.isRender = true;
      this.$GlobalLoading.hide();
    } catch (error) {
      const errorObj = error as Error;
      const errorMessage = errorObj.message || '불특정 에러가 발생했습니다.';
      alert(errorMessage);
      return;
    }
  },
  methods: {
    async goNextStep(params) {
      if (!this.formState.terms.agree1) {
        alert('모든 약관에 동의해주세요.');
        window.scroll({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
        return;
      }

      try {
        console.log({ ...params, targetSeqNo: this.refundSeqNo });
        const { data } = await createRefundsForInfra({ ...params, targetSeqNo: this.refundSeqNo });
        alert('환불정보 입력완료');

        console.log(data);
        this.$router.push({
          name: 'ems-02',
          params: { service_type: 'nims' },
          query: { payment_seqno: this.paymentSeqNo },
        });
      } catch (error) {
        console.log(error);
        alert('error');
      }
    },

    async getRefundsInfoFnc() {
      if (!this.paymentSeqNo) {
        throw new Error(ERROR_CODE_OBJ.WRONG_PARAMS);
      }

      const { data } = await getRefundsInfo(this.paymentSeqNo);

      if (!data.data.length) {
        throw new Error(ERROR_CODE_OBJ.NO_MATCHING_INFO);
      } else if (data.data[0].refund !== null) {
        throw new Error(ERROR_CODE_OBJ.EXIST_REFUNDS_INFO);
      } else {
        this.refundSeqNo = data.data[0].seqno;
      }
    },
  },
});
