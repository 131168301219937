
// https://temp-application.gabia.com:8080/modify-step-01/nims?group=93721
import Vue from 'vue';
import BillLayout from '@/layouts/BillLayout.vue';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import { filter } from 'vue/types/umd';

const maxSizeInBytes = 10 * 1024 * 1024;

export default Vue.extend({
  name: 'NimsEmsStep01Template',
  components: { BillLayout, FormLayout, TableLayout, ColumnLayout },
  model: { prop: 'formState', event: 'change' },
  props: {
    formState: {
      type: Object,
      default() {
        return {
          period: '1',
          groupExpireDate: '',
          selectedProductId: '',
          terms: {
            agree1: false,
          },
        };
      },
    },
  },
  data() {
    return {
      selectedFile: null as File | null,

      tempFileName: '',
      agreeAll: false,
      settlementInfo: {
        userName: '',
        bankName: '',
        bankAccountNo: '',
      },
      isNotSameCustomerName: false,
      formTitle,
      bankArr: [
        {
          label: '은행을 선택해주세요.',
          value: '',
        },
        {
          label: '농협은행',
          value: '농협은행',
        },
        {
          label: '신한은행',
          value: '신한은행',
        },
        {
          label: '하나은행',
          value: '하나은행',
        },
        {
          label: '우리은행',
          value: '우리은행',
        },
        {
          label: '국민은행',
          value: '국민은행',
        },
        {
          label: '기업은행',
          value: '기업은행',
        },
        {
          label: '경남은행',
          value: '경남은행',
        },
        {
          label: '대구은행',
          value: '대구은행',
        },
        {
          label: '새마을금고',
          value: '새마을금고',
        },
        {
          label: '씨티은행',
          value: '씨티은행',
        },
        {
          label: '우체국',
          value: '우체국',
        },
        {
          label: 'SC제일은행',
          value: 'SC제일은행',
        },
        {
          label: '삼성증권',
          value: '삼성증권',
        },
        {
          label: '광주은행',
          value: '광주은행',
        },
        {
          label: '부산은행',
          value: '부산은행',
        },
        {
          label: '산업은행',
          value: '산업은행',
        },
        {
          label: '제주은행',
          value: '제주은행',
        },
        {
          label: '수협중앙회',
          value: '수협중앙회',
        },
        {
          label: '신협중앙회',
          value: '신협중앙회',
        },
        {
          label: '전북은행',
          value: '전북은행',
        },
        {
          label: '케이뱅크',
          value: '케이뱅크',
        },
        {
          label: '유안타증권',
          value: '유안타증권',
        },
        {
          label: '토스뱅크',
          value: '토스뱅크',
        },
      ],
    };
  },
  computed: {
    agreeBoth(): boolean {
      return !!this.formState.terms.agree1;
    },
    fileBtnText(): string {
      return !!this.selectedFile ? '변경' : '파일 선택';
    },
    userName(): string {
      return this.$store.state.userInfo?.user_name ?? '';
    },
  },
  watch: {
    agreeBoth(newValue) {
      this.agreeAll = newValue;
    },
  },
  mounted() {
    this.settlementInfo.userName = this.userName;
  },
  methods: {
    onChangeAgreeAll(value) {
      if (value) {
        this.$emit('change', {
          ...this.formState,
          terms: {
            agree1: true,
          },
        });
      } else {
        this.$emit('change', {
          ...this.formState,
          terms: {
            agree1: false,
          },
        });
      }
    },
    onChangeSameName(value) {
      this.isNotSameCustomerName = value;
      if (!this.isNotSameCustomerName) {
        this.tempFileName = '';
        this.selectedFile = null;
        this.settlementInfo.userName = this.userName;
      }
    },
    handleAccountInput() {
      // 숫자와 '-'만을 남기고 나머지 문자 제거
      this.settlementInfo.bankAccountNo = this.settlementInfo.bankAccountNo.replace(/[^0-9]/g, '');
    },
    removePlaceHolder() {
      this.bankArr = this.bankArr.filter((item) => {
        return item.value !== '';
      });
    },
    selectFile() {
      // 파일 선택 버튼 클릭 시, 실제로 보이지 않는 input file 엘리먼트 클릭
      const fileInput = this.$refs.fileInput as HTMLInputElement | undefined;

      if (fileInput) {
        fileInput.click();
      }
    },
    handleFileChange(event) {
      const fileInput = event.target;
      if (fileInput.files.length > 0) {
        // 파일이 선택되었을 때만 처리
        this.selectedFile = fileInput.files[0];

        // 확장자 제한
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'pdf'];
        const fileExtension = this.selectedFile?.name.split('.').pop()?.toLowerCase() || '';
        const fileSize = this.selectedFile?.size || 0;

        if (!allowedExtensions.includes(fileExtension)) {
          alert('올바른 확장자의 파일을 선택해주세요.');
          this.selectedFile = null;
          return;
        }

        if (fileSize > maxSizeInBytes) {
          alert('10MB 이하의 파일을 선택해주세요.');
          return;
        }

        this.tempFileName = this.selectedFile?.name || '';
        console.log(this.selectedFile);
      } else {
        // 파일이 선택되지 않았을 때는 null로 초기화
        this.selectedFile = null;
        this.tempFileName = '';
      }
    },
    goNextStepToBrain() {
      const fileInput = this.$refs.fileInput as HTMLInputElement;

      if (this.isNotSameCustomerName && this.selectedFile === null) {
        alert('회원 정보와 다른 예금주를 입력할 경우, 통장 사본이 반드시 필요합니다.');
        return;
      }

      if (this.settlementInfo.userName.trim() === '') {
        alert('예금주를 입력해주세요');
        return;
      }

      if (this.settlementInfo.bankName.trim() === '') {
        alert('은행을 선택해주세요.');
        return;
      }

      if (this.settlementInfo.bankAccountNo.trim() === '') {
        alert('계좌번호를 입력해주세요');
        return;
      }

      let params = {
        ...this.settlementInfo,
      };

      if (fileInput) {
        params['attchFile'] = fileInput.files?.length ? fileInput.files[0] : null;
      }

      this.$emit('next-step-btn-click', params);
    },
    onChangeTerms(e) {
      const { name, checked } = e;

      const params = {
        ...this.formState,
        terms: {
          ...this.formState.terms,
          [`agree${name}`]: checked,
        },
      };
      this.$emit('change', params);
    },
  },
});
